import React from 'react';

import './component-styles/Footer.css'

export default class Footer extends React.Component {
    render() {

        const toTop = () => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }

        return(
            <footer className="ec-kpi-footer">
                <i class="fas fa-arrow-up ec-kpi-footer-arrow" onClick={toTop}></i>
            </footer>
        )
    }
}