import React from 'react';

import sanityClient from '../sanitySetup';

import Header from '../components/Header.jsx';
import CPSignUp from '../components/CPSignUp.jsx';
import Footer from '../components/Footer.jsx';

import './page-styles/Home.css';

export default class Home extends React.Component {

    state = {
        companyName: '',
        domain: '',
        email: '',
        firstName: '',
        lastName: '',
        averageOrderValue: '',
        conversionRate: '',
        sessions: '',
        lastMonthRevenue: '',
        targetRevenue: '',
        accessToMarket: '',
        sustainableProfitMargin: '',
        barrierToEntry: '',
        trafficImpact: false,
        aovImpact: false,
        conversionImpact: false,
        submissionErrors: false,
        revenueResult: "",
        sessionsResult: "",
        aovResult: "",
        conversionResult: "",
        formSubmitted: false
    }

    render() {

        let errorMessage;
        let submissionSentence;
        let impactOptions;
        let qualitativeQuestions;
        let personalInformation;

        if (this.state.submissionErrors) {
            errorMessage = 
                <div className="ec-kpi-form-error-container">
                    <p className="ec-kpi-form-error-message">*Please fill all of the required fields</p>
                </div>
        } else {
            errorMessage = ""
        }

        if (this.state.formSubmitted) {
            submissionSentence = 
            <div>
                <h2 className="ec-kpi-calculation-subheader-results">In order to increase your revenue to <span className="ec-kpi-results-sentence">${this.state.revenueResult}</span> you'll need to increase your sessions to <span className="ec-kpi-results-sentence">{this.state.sessionsResult}</span> people per month, your conversion rate to <span className="ec-kpi-results-sentence">{this.state.conversionResult}%</span>, and your average order value to <span className="ec-kpi-results-sentence">${this.state.aovResult}</span></h2>
                <h2 className="ec-kpi-calculation-subheader-results">To learn more about how you can take control of your business and increase your metrics, sign up for our lunch & learn below</h2>
            </div>;
            impactOptions = "";
            qualitativeQuestions = "";
            personalInformation = ""

        } else {
            submissionSentence = ""
            impactOptions =
                <div className="ec-kpi-calculator-inputs-container">
                    <h2 className="ec-kpi-calculator-inputs-header">what can you impact?</h2>
                    <div className="ec-kpi-calculator-impact-container">
                        <div className={this.state.trafficImpact ? "ec-kpi-calculator-single-impact-container-active" : "ec-kpi-calculator-single-impact-container"} onClick={() => this.setState({trafficImpact: !this.state.trafficImpact})}>
                            <h3 className="ec-kpi-calculator-single-impact-header">I can:</h3>
                            <h3 className="ec-kpi-calculator-single-impact-header">drive more traffic</h3>
                            <p className="ec-kpi-calculator-single-impact-text">I have the marketing budget and all owned media platforms and can increase my impressions and CTR</p>
                        </div>
                        <div className={this.state.aovImpact ? "ec-kpi-calculator-single-impact-container-active" : "ec-kpi-calculator-single-impact-container"} onClick={() => this.setState({aovImpact: !this.state.aovImpact})}>
                            <h3 className="ec-kpi-calculator-single-impact-header">I can:</h3>
                            <h3 className="ec-kpi-calculator-single-impact-header">increase my AOV</h3>
                            <p className="ec-kpi-calculator-single-impact-text">I have control of the product catalog, pricing, up sell and cross sell</p>
                        </div>
                        <div className={this.state.conversionImpact ? "ec-kpi-calculator-single-impact-container-active" : "ec-kpi-calculator-single-impact-container"} onClick={() => this.setState({conversionImpact: !this.state.conversionImpact})}>
                            <h3 className="ec-kpi-calculator-single-impact-header">I can:</h3>
                            <h3 className="ec-kpi-calculator-single-impact-header">increase my conversion rate</h3>
                            <p className="ec-kpi-calculator-single-impact-text">I can optimize my site, offer promotions, and increase my review collection</p>
                        </div>
                    </div>
                </div>;

            qualitativeQuestions = 
                <section className="ec-kpi-qualitative-container">
                    <h2 className="ec-kpi-qualitative-header">characteristics of a great e-commerce business</h2>
                    <div className="ec-kpi-qualitative-single-metric">
                        <h3 className="ec-kpi-qualitative-single-metric-header">access to market</h3>
                        <p className="ec-kpi-qualitative-single-metric-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id leo in vitae turpis massa.</p>
                        <textarea className="ec-kpi-qualitative-single-metric-textarea" name="accessToMarket" id="accessToMarket" cols="30" rows="5" onChange={this.textChange}></textarea>
                    </div>
                    <div className="ec-kpi-qualitative-single-metric">
                        <h3 className="ec-kpi-qualitative-single-metric-header">sustainable profit margin</h3>
                        <p className="ec-kpi-qualitative-single-metric-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id leo in vitae turpis massa.</p>
                        <textarea className="ec-kpi-qualitative-single-metric-textarea" name="sustainableProfitMargin" id="sustainableProfitMargin" cols="30" rows="5" onChange={this.textChange}></textarea>
                    </div>
                    <div className="ec-kpi-qualitative-single-metric">
                        <h3 className="ec-kpi-qualitative-single-metric-header">barrier to entry</h3>
                        <p className="ec-kpi-qualitative-single-metric-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id leo in vitae turpis massa.</p>
                        <textarea className="ec-kpi-qualitative-single-metric-textarea" name="barrierToEntry" id="barrierToEntry" cols="30" rows="5" onChange={this.textChange}></textarea>
                    </div>
                </section>

            personalInformation = 
            <form 
                className="ec-kpi-form-inputs-container" 
                name="registration"
                method="POST"
                onSubmit={this.handleSubmit}
            >
                <div className="ec-kpi-form-div">
                    <p>
                        <label htmlFor="firstName" className="ec-kpi-form-label">First Name<span className="ec-star-span">*</span></label>
                        <input type="text" id='firstName' name="firstName" className="ec-kpi-form-input" onChange={this.textChange}/>
                    </p>
                    <p>
                        <label htmlFor="lastName" className="ec-kpi-form-label">Last Name<span className="ec-star-span">*</span></label>
                        <input type="text" id='lastName' name="lastName" className="ec-kpi-form-input" onChange={this.textChange}/>
                    </p>
                    <p>
                        <label htmlFor="companyName" className="ec-kpi-form-label">Company Name<span className="ec-star-span">*</span></label>
                        <input type="text" id='companyName' name="companyName"  className="ec-kpi-form-input" onChange={this.textChange}/>
                    </p>
                    <p>
                        <label htmlFor="domain" className="ec-kpi-form-label">Domain<span className="ec-star-span">*</span></label>
                        <input type="text" id='domain' name="domain" className="ec-kpi-form-input" onChange={this.textChange}/>
                    </p>
                    <p>
                        <label htmlFor="email" className="ec-kpi-form-label">Email Address<span className="ec-star-span">*</span></label>
                        <input type="text" id='email' name="email" className="ec-kpi-form-input" onChange={this.textChange}/>
                    </p>
                </div>
                <div className="ec-kpi-form-submit-container">
                    <button className="ec-kpi-form-submit-button" type="submit">SUBMIT FORM</button>
                </div>
            </form>
            }




        return(
            <div className="ec-kpi-primary-container">
                <Header />
                <main className="ec-kpi-main">
                    <div className="ec-kpi-calculator-container">
                        <h2 className="ec-kpi-calculator-header">online revenue</h2>
                        <h3 className="ec-kpi-calculator-subheader">IT'S IN YOUR CONTROL</h3>
                        <div className="ec-kpi-calculations-container">
                            <div className="ec-kpi-calculations-primary-header-container">
                                <h4 className="ec-kpi-calculator-equation-header">current:</h4>
                                <h4 className="ec-kpi-calculator-equation-header">target:</h4>
                            </div>
                            <div className="ec-kpi-calculations-sessions-container">
                                <div className="ec-kpi-calculator-stat-container">
                                <div className="ec-kpi-calculations-header-container-mobile">
                                    <h3 className="ec-kpi-calculator-results-header">Monthly Website Sessions</h3>
                                </div>
                                    <input type="number" id="sessions" name="sessions" onChange={this.textChange} className="ec-kpi-calculator-results-input"/>
                                </div>
                                <div className="ec-kpi-calculations-header-container-desktop">
                                    <h3 className="ec-kpi-calculator-results-header">Monthly Website Sessions</h3>
                                </div>
                                <div className="ec-kpi-calculator-stat-container">
                                <div className="ec-kpi-calculations-header-container-mobile">
                                    <h3 className="ec-kpi-calculator-results-header">Monthly Website Sessions</h3>
                                </div>
                                    <input type="text" name="sessionsResult" id="sessionsResult" value={`${this.state.sessionsResult}`} className="ec-kpi-calculator-results-input-blocked"/>
                                </div>
                            </div>
                            <div className="ec-kpi-calculations-aov-container">
                                <div className="ec-kpi-calculator-stat-container">
                                <div className="ec-kpi-calculations-header-container-mobile">
                                    <h3 className="ec-kpi-calculator-results-header">Average Order Value</h3>
                                </div>
                                    <input type="number" id="averageOrderValue" name="averageOrderValue" onChange={this.textChange} className="ec-kpi-calculator-results-input"/>
                                </div>
                                <div className="ec-kpi-calculations-header-container-desktop">
                                    <h3 className="ec-kpi-calculator-results-header">Average Order Value</h3>
                                </div>
                                <div className="ec-kpi-calculator-stat-container">
                                <div className="ec-kpi-calculations-header-container-mobile">
                                    <h3 className="ec-kpi-calculator-results-header">Average Order Value</h3>
                                </div>
                                    <input type="text" name="aovResult" id="aovResult" value={`$${this.state.aovResult}`} className="ec-kpi-calculator-results-input-blocked"/>
                                </div>
                            </div>
                            <div className="ec-kpi-calculations-conversion-container">
                                <div className="ec-kpi-calculator-stat-container">
                                <div className="ec-kpi-calculations-header-container-mobile">
                                    <h3 className="ec-kpi-calculator-results-header">Website Conversion Rate:</h3>
                                </div>
                                    <input type="number" id="conversionRate" name="conversionRate" onChange={this.textChange} className="ec-kpi-calculator-results-input"/>
                                </div>
                                <div className="ec-kpi-calculations-header-container-desktop">
                                    <h3 className="ec-kpi-calculator-results-header">Website Conversion Rate:</h3>
                                </div>
                                <div className="ec-kpi-calculator-stat-container">
                                    <div className="ec-kpi-calculations-header-container-mobile">
                                        <h3 className="ec-kpi-calculator-results-header">Website Conversion Rate:</h3>
                                    </div>
                                    <input type="text" name="conversionResult" id="conversionResult" value={`${this.state.conversionResult}%`} className="ec-kpi-calculator-results-input-blocked"/>
                                </div>
                            </div>
                            <div className="ec-kpi-calculations-monthly-revenue-container">
                                <div className="ec-kpi-calculator-stat-container">
                                    <div className="ec-kpi-calculator-submission-line"></div>
                                    <div className="ec-kpi-calculations-header-container-mobile">
                                        <h3 className="ec-kpi-calculator-results-header">Monthly Revenue</h3>
                                    </div>
                                    <input type="text" name="revenue" id="revenue" value={`$${(this.state.averageOrderValue * this.state.sessions * (this.state.conversionRate/100)).toFixed(2)}`} className="ec-kpi-calculator-results-input-blocked" />
                                </div>
                                <div className="ec-kpi-calculations-header-container-desktop">
                                    <h3 className="ec-kpi-calculator-results-header">Monthly Revenue</h3>
                                </div>
                                <div className="ec-kpi-calculator-stat-container">
                                    <div className="ec-kpi-calculations-header-container-mobile">
                                        <div className="ec-kpi-calculator-submission-line"></div>
                                        <h3 className="ec-kpi-calculator-results-header">Monthly Revenue</h3>
                                    </div>
                                    <input type="number" id="targetRevenue" name="targetRevenue"  onChange={this.textChange} className="ec-kpi-calculator-results-input" />
                                </div>
                            </div>

                        </div>
                            {submissionSentence}
                            {impactOptions}
                    </div>
                    {qualitativeQuestions}
                    <section className="ec-kpi-form-container">
                        {personalInformation}
                        {errorMessage}
                    </section>
                    <CPSignUp/>
                </main>
                <Footer />
            </div>
        )
    }

// Updates the state for the appropriate field to contain the value in the input field
    textChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setState(() => {
            return {
            [name]: value
            };
        });
    }

    handleSubmit = (event) => {

        event.preventDefault()


        const data = {
            _type: 'formSubmissions',
            companyName: this.state.companyName,
            domain: this.state.domain,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            averageOrderValue: this.state.averageOrderValue,
            conversionRate: this.state.conversionRate,
            sessions: this.state.sessions,
            lastMonthRevenue: this.state.lastMonthRevenue,
            targetRevenue: this.state.targetRevenue,
            accessToMarket: this.state.accessToMarket,
            sustainableProfitMargin: this.state.sustainableProfitMargin,
            barrierToEntry: this.state.barrierToEntry,
        }

        if (this.state.companyName !== "" && this.state.domain !== "" && this.state.email !== "" && this.state.firstName !== "" && this.state.lastName !== "" && this.state.averageOrderValue !== "" && this.state.conversionRate !== "" && this.state.sessions !== "" && this.state.targetRevenue !== "" && this.state.accessToMarket !== "" && this.state.sustainableProfitMargin !== "" && this.state.barrierToEntry !== "") {
            this.setState({
                submissionErrors: false,
                formSubmitted: true
            })

            sanityClient.create(data)
                .then(res => console.log(res))
                .catch(err => console.log(err))
            console.log("all good")
            this.produceResults();
            window.scroll({
                top: 400,
                left: 0,
                behavior: 'smooth'
            })

        } else {
            console.log("field missing")
            this.setState({
                submissionErrors: true
            })
        }
    }

    produceResults = (event) => {

        let counter = 0;
        const percentIncrease = ((this.state.targetRevenue / (this.state.averageOrderValue * (this.state.conversionRate / 100) * this.state.sessions)));
        const trafficUpgrade = this.state.sessions;
        const aovUpgrade = this.state.averageOrderValue;
        const conversionUpgrade = this.state.conversionRate;

        this.setState({
            revenueResult: this.state.targetRevenue
        })

        if (this.state.trafficImpact) {
            counter++
        }

        if (this.state.aovImpact) {
            counter++
        }

        if (this.state.conversionImpact) {
            counter++
        }

        if (counter === 1) {
            const increaseOne = percentIncrease
            if (this.state.conversionImpact) {
                this.setState({
                    conversionResult: (conversionUpgrade * increaseOne).toFixed(2)
                })
            } else {
                this.setState({
                    conversionResult: this.state.conversionRate
                })
            }

            if (this.state.trafficImpact) {
                this.setState({
                    sessionsResult: Math.round(trafficUpgrade * increaseOne)
                })
            } else {
                this.setState({
                    sessionsResult: this.state.sessions 
                })
            }

            if (this.state.aovImpact) {
                this.setState({
                    aovResult: (aovUpgrade * increaseOne).toFixed(2)
                })
            } else {
                this.setState({
                    aovResult: this.state.averageOrderValue 
                })
            }

        } else if (counter === 2) {
            const increaseTwo = (((percentIncrease - 1) * 0.48254) + 1)
            if (this.state.conversionImpact) {
                this.setState({
                    conversionResult: (conversionUpgrade * increaseTwo).toFixed(2)
                })
            } else {
                this.setState({
                    conversionResult: this.state.conversionRate
                })
            }

            if (this.state.trafficImpact) {
                this.setState({
                    sessionsResult: Math.round(trafficUpgrade * increaseTwo)
                })
            } else {
                this.setState({
                    sessionsResult: this.state.sessions 
                })
            }

            if (this.state.aovImpact) {
                this.setState({
                    aovResult: (aovUpgrade * increaseTwo).toFixed(2)
                })
            } else {
                this.setState({
                    aovResult: this.state.averageOrderValue 
                })
            }

        } else if (counter === 3 || counter === 0) {
            const increaseThree = (((percentIncrease - 1) * 0.317927) + 1)
            if (this.state.conversionImpact) {
                this.setState({
                    conversionResult: (conversionUpgrade * increaseThree).toFixed(2)
                })
            } else {
                this.setState({
                    conversionResult: this.state.conversionRate
                })
            }

            if (this.state.trafficImpact) {
                this.setState({
                    sessionsResult: Math.round(trafficUpgrade * increaseThree)
                })
            } else {
                this.setState({
                    sessionsResult: this.state.sessions 
                })
            }

            if (this.state.aovImpact) {
                this.setState({
                    aovResult: (aovUpgrade * increaseThree).toFixed(2)
                })
            } else {
                this.setState({
                    aovResult: this.state.averageOrderValue 
                })
            }
        }
        console.log(counter)
        console.log(percentIncrease)

    }
}