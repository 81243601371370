import React from 'react';

import './component-styles/Header.css';

export default class Header extends React.Component {
    render() {
        return(
            <header className="ec-kpi-header" id="top">
                <div className="ec-kpi-header-logo-container">
                    <a href="https://ecommercecanada.com" rel="noopener" className="ec-kpi-header-link" target="_blank"><img src="./images/EC_Logo_White.png" alt="eCommerce Canada Logo" className="ec-kpi-header-logo"/></a>
                    <a href="https://www.canadapost-postescanada.ca/"rel="noopener" className="ec-kpi-header-link-cp" target="_blank"><img src="./images/canada-post-logo-1.png" alt="eCommerce Canada Logo" className="ec-kpi-header-logo"/></a>
                </div>
                <h1 className="ec-kpi-header-header">build a successful<br/> e-commerce company</h1>
                <h3 className="ec-kpi-header-subheader">WE'RE HERE TO HELP!</h3>
            </header>
        )
    }
}