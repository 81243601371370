import React from 'react';

import './component-styles/CPSignUp.css';

export default class CPSignUp extends React.Component {
    render() {
        return(
            <section className="ec-kpi-cp-signup-container">
                <div className="ec-kpi-cp-signup-left">
                    <h2 className="ec-kpi-cp-signup-header">sign up!</h2>
                    <div className="ec-kpi-cp-logo-container">
                        <img src="./images/cp-logo.png" className="ec-kpi-cp-signup-cp-logo" alt="Canada Post logo"/>
                    </div>
                    
                </div>
                <div className="ec-kpi-cp-signup-right">
                    <h2 className="ec-kpi-cp-signup-header">lunch & learn</h2>
                    <p className="ec-kpi-cp-signup-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <button className="ec-kpi-cp-signup-button">SIGN UP</button>
                </div>
            </section>
        )
    }
}
